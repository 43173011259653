<template>
	<b-col cols="auto">
		<h2>Contact</h2>
		<dl>
			<template v-for="entry in entries">
				<dt :key="entry.text"><font-awesome-icon :icon="entry.icon" class="mr-2" fixed-width/></dt>
				<dd>
					<component :is="entry.href ? 'b-link' : 'span'" :href="entry.href" target="_blank" class="text-light">
						<span v-for="line in entry.lines" :key="line" class="d-inline-block" style="white-space: break-spaces;">{{ line }}</span>
					</component>
				</dd>
			</template>
		</dl>
	</b-col>
</template>

<script>
	export default {
		data() {
			return {
				entries: [
					{
						icon: 'fa-solid fa-user-tie',
						lines: ['Jed Blume, M.A.']
					},
					{
						icon: 'fa-solid fa-file-pen',
						lines: ['Grants Specialist/', 'Development Consultant']
					},
					{
						icon: 'fa-solid fa-map-location-dot',
						lines: ['Brattleboro, VT 05301'],
						href: 'https://goo.gl/maps/yXfUWQGChQKHcqxw9'
					},
					{
						icon: 'fa-solid fa-phone',
						lines: ['+1 (856) 577-4589'],
						href: 'tel:+18565774589'
					},
					{
						icon: 'fa-solid fa-envelope',
						lines: ['Jed.Blume@gmail.com'],
						href: 'mailto:Jed.Blume@gmail.com'
					}
				]
			};
		}
	};
</script>

<style lang="scss" scoped>
	dl {
		display: inline-grid;
		grid-template-columns: min-content auto;
		margin: 0;
	}

	dt {
		grid-column: 1;
	}

	dd {
		grid-column: 2;
	}
</style>
