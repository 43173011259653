<template>
	<b-col cols="auto" sm="8" md="4">
		<b-form-row v-for="(row, index) in credentials" :key="index" class="align-items-center justify-content-center">
			<b-col cols="4" v-for="credential in row" :key="credential" class="my-1">
				<b-link @click="showCredentials = true; selectedCredential = credentials.flat().indexOf(credential);">
					<b-img :src="`/assets/images/credentials/138x109/${credential}`" width="138" height="109" class="credential-link" fluid-grow rounded/>
				</b-link>
			</b-col>
		</b-form-row>
		<b-modal v-model="showCredentials" hide-header hide-footer>
			<b-carousel
				v-model="selectedCredential"
				:interval="0"
				controls
				indicators
				fade
			>
				<b-carousel-slide
					v-for="credential in credentials.flat()"
					:key="credential"
					:img-src="`/assets/images/credentials/1500x1200/${credential}`"
					img-width="1500"
					img-height="1200"
				/>
			</b-carousel>
		</b-modal>
	</b-col>
</template>

<script>
	export default {
		data() {
			return {
				showCredentials: false,
				selectedCredential: null,
				credentials: [
					[
						'diploma-sit.jpg',
						'diploma-rowan-philosophy-religion.jpg'
					],
					[
						'diploma-rowan-english.jpg',
						'award-rowan-medallion.jpg',
						'award-rowan-john-roch.jpg'
					],
					[
						'award-rowan-dean.jpg',
						'award-rowan-charles-wei-hsun-fu.jpg'
					]
				]
			};
		}
	};
</script>

<style lang="scss" scoped>
	::v-deep {
		.modal {
			backdrop-filter: blur(5px);
		}

		.modal-dialog {
			width: 100%;
			max-width: none;
			height: 100%;
			margin: 0;
			padding: 1rem;
		}

		.modal-content {
			height: 100%;
			border: 0;
			border-radius: 0;
			background: transparent;
			pointer-events: none;
		}

		.modal-body {
			position: absolute;
			margin: auto;
			padding: 0;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			max-width: 100%;
			max-height: 100%;
			aspect-ratio: 5 / 4;
		}

		.carousel {
			height: 100%;
			pointer-events: auto;
		}

		.carousel-inner,
		.carousel-item {
			height: 100%;
		}

		.carousel-item img {
			height: 100%;
			background-color: #c5beb6;
			object-fit: contain;
		}
	}
</style>
