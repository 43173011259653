import Vue from 'vue';

import '@/config/boostrap-vue.js';
import '@/config/font-awesome.js';

import App from '@/components/App.vue';

import Modal from '@/components/Modal';
Vue.component('modal', Modal);

Vue.config.productionTip = false;

new Vue({
	render: h => h(App)
}).$mount('#app');
