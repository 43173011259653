<template>
	<b-modal
		:visible="Boolean(value)"
		@change="$emit('input', $event)"
		centered
		hide-footer
		scrollable
		v-bind="$attrs"
		v-on="$listeners"
	>
		<template #modal-title>
			<font-awesome-icon v-if="icon" :icon="icon" v-bind="iconAttrs"/>
			{{ title }}
		</template>
		<slot/>
	</b-modal>
</template>

<script>
	export default {
		props: {
			value: null,
			icon: String,
			iconAttrs: Object,
			title: String
		}
	}
</script>

<style scoped>
	::v-deep .modal {
		backdrop-filter: blur(5px);
	}
</style>
