import Vue from 'vue';

import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
Vue.component('font-awesome-icon', FontAwesomeIcon);

import {library} from '@fortawesome/fontawesome-svg-core';

import {faArrowRotateLeft} from '@fortawesome/free-solid-svg-icons/faArrowRotateLeft';
library.add(faArrowRotateLeft);

import {faBars} from '@fortawesome/free-solid-svg-icons/faBars';
library.add(faBars);

import {faChalkboardUser} from '@fortawesome/free-solid-svg-icons/faChalkboardUser';
library.add(faChalkboardUser);

import {faChartLine} from '@fortawesome/free-solid-svg-icons/faChartLine';
library.add(faChartLine);

import {faEnvelope} from '@fortawesome/free-solid-svg-icons/faEnvelope';
library.add(faEnvelope);

import {faFileLines} from '@fortawesome/free-solid-svg-icons/faFileLines';
library.add(faFileLines);

import {faFilePen} from '@fortawesome/free-solid-svg-icons/faFilePen';
library.add(faFilePen);

import {faFlag} from '@fortawesome/free-solid-svg-icons/faFlag';
library.add(faFlag);

import {faHandshake} from '@fortawesome/free-solid-svg-icons/faHandshake';
library.add(faHandshake);

import {faMapLocationDot} from '@fortawesome/free-solid-svg-icons/faMapLocationDot';
library.add(faMapLocationDot);

import {faPeopleRoof} from '@fortawesome/free-solid-svg-icons/faPeopleRoof';
library.add(faPeopleRoof)

import {faPhone} from '@fortawesome/free-solid-svg-icons/faPhone';
library.add(faPhone);;

import {faUserTie} from '@fortawesome/free-solid-svg-icons/faUserTie';
library.add(faUserTie);
