<template>
	<modal title="Workshop" icon="fa-solid fa-chalkboard-user" body-class="p-0" v-bind="$attrs" v-on="$listeners">
		<b-list-group class="rounded" flush>
			<b-list-group-item v-for="link in links" :key="link.href" :href="link.href" target="_blank">
				{{ link.text }}
			</b-list-group-item>
		</b-list-group>
	</modal>
</template>

<script>
	export default {
		data() {
			return {
				links: [
					{
						href: '/assets/documents/workshop/2018 NEYT -- MOU for TST (Sample).pdf',
						text: 'Sample MOU'
					},
					{
						href: '/assets/documents/workshop/2019 Rich Earth - VT Legislators LoS - NSF SBIR (120519).pdf',
						text: 'Sample Letter of Support'
					},
					{
						href: '/assets/documents/workshop/GAL RFP (2018)_0.pdf',
						text: 'Sample RFP'
					},
					{
						href: '/assets/documents/workshop/Block Foundation 2018 Form 990.pdf',
						text: 'Sample Form 990 (Pages 14 -25)'
					},
					{
						href: '/assets/documents/workshop/2020 VJC - Thompson Trust Cover Letter.pdf',
						text: 'Sample Cover Letter'
					},
					{
						href: 'https://canadayfamily.org/fields-of-interest/',
						text: 'Grantmaker Example #1 (Family Foundation)'
					},
					{
						href: 'https://vermontcf.org/nonprofits-and-grantseekers/available-grants',
						text: 'Grantmaker Example #2 (Community Foundation)'
					},
					{
						href: '/assets/documents/workshop/2020 BGCB - Windham Foundation Proposal Narrative.pdf',
						text: 'Sample Proposal Narrative'
					},
					{
						href: '/assets/documents/workshop/Sample Evaluation Plan.pdf',
						text: 'Sample Evaluation Plan'
					}
				]
			};
		}
	}
</script>
