<template>
	<div class="app-container">
		<navbar/>
		<banner/>
		<introduction/>
		<mission/>
		<about/>
		<partners/>
		<google-maps/>
		<conclusion/>
	</div>
</template>

<script>
	import About from '@/components/About.vue';
	import Banner from '@/components/Banner.vue';
	import Conclusion from '@/components/Conclusion.vue';
	import Contact from '@/components/Contact.vue';
	import GoogleMaps from '@/components/GoogleMaps.vue';
	import Introduction from '@/components/Introduction.vue';
	import Mission from '@/components/Mission.vue';
	import Navbar from '@/components/Navbar.vue';
	import Partners from '@/components/Partners.vue';

	export default {
		components: {
			About,
			Banner,
			Conclusion,
			Contact,
			GoogleMaps,
			Introduction,
			Mission,
			Navbar,
			Partners
		}
	};
</script>

<style lang="scss">
	html {
		scroll-behavior: smooth;
	}

	@media screen and (prefers-reduced-motion: reduce) {
		html {
			scroll-behavior: auto;
		}
	}

	a {
		&, * {
			transition: all .2s ease-in-out;

			img:hover {
				transform: scale(1.1);
				filter: brightness(1.25);
			}
		}
	}
</style>
