<template>
	<b-navbar ref="navbar" toggleable="lg" variant="light" sticky>
		<b-container>
			<b-navbar-brand href="#intro">
				<span class="d-none d-sm-inline">Green Mountain Grant Services</span>
				<span class="d-inline d-sm-none">Green Mountain Grants</span>
			</b-navbar-brand>
			<b-navbar-toggle target="navbar-collapse" class="border-0">
				<font-awesome-icon icon="fa-solid fa-bars"/>
			</b-navbar-toggle>
			<b-collapse id="navbar-collapse" is-nav>
				<b-navbar-nav v-b-scrollspy="{offset: navbarHeight}">
					<b-nav-item href="#mission">Mission</b-nav-item>
					<b-nav-item href="#about">About</b-nav-item>
					<b-nav-item href="#partners">Partners</b-nav-item>
					<b-nav-item href="#contact">Contact</b-nav-item>
				</b-navbar-nav>
				<b-navbar-nav class="ml-auto">
					<b-nav-item href="/assets/documents/Jed Blume - Resume.pdf" target="_blank">Resume</b-nav-item>
					<b-nav-item @click="showWorkshop = true">Workshop</b-nav-item>
					<workshop v-model="showWorkshop"/>
				</b-navbar-nav>
			</b-collapse>
		</b-container>
	</b-navbar>
</template>

<script>
	import Workshop from '@/components/Workshop';

	export default {
		components: {
			Workshop
		},
		data() {
			return {
				navbarHeight: 0,
				showWorkshop: false
			}
		},
		mounted() {
			this.resizeObserver = new ResizeObserver(() => document.documentElement.style
				.setProperty('--navbar-height', `${this.navbarHeight = this.$refs.navbar.$el.offsetHeight}px`));
			this.resizeObserver.observe(this.$refs.navbar.$el);
		},
		beforeDestroy() {
			this.resizeObserver.disconnect();
		}
	}
</script>

<style>
	:root {
		--navbar-height: 0;
	}

	:target {
		scroll-margin-top: var(--navbar-height);
	}
</style>
