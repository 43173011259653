<template>
	<b-container id="about">
		<b-row class="align-items-center my-5">
			<b-col md="4">
				<figure class="figure mb-4 mb-md-0">
					<b-img src="/assets/images/cheese.jpg" width="370" height="316" class="figure-img" fluid-grow rounded/>
					<figcaption class="figure-caption">Jed announcing raffle winners at Brattleboro Memorial Hospital's 2012 Touch a Truck Event.</figcaption>
				</figure>
			</b-col>
			<b-col md="6" offset-md="1">
				<h2>About</h2>
				<p>Green Mountain Grant Services is a member of The Foundation Center and The Foundation Directory Online Database.</p>
				<p>
					Green Mountain Grant Services facilitates
					<b-link @click="showStrategicPlanning = true">strategic planning</b-link>
					<strategic-planning v-model="showStrategicPlanning"/>
					as well as all aspects of the
					<b-link @click="showGrantCycle = true">grant cycle</b-link>
					<grant-cycle v-model="showGrantCycle"/>
					including:
				</p>
				<b-row align-h="center" align-v="center">
					<b-col cols="auto">
						<ul>
							<li>Researching viable funders</li>
							<li>Mapping strategic plans</li>
							<li>Designing projects</li>
							<li>Communicating with donors & foundations</li>
							<li>Drafting proposals</li>
							<li>Submitting evaluation reports</li>
						</ul>
					</b-col>
					<b-col cols="auto">
						<b-button variant="primary" href="/assets/documents/Jed Blume - Resume.pdf" target="_blank">
							<font-awesome-icon icon="fa-solid fa-file-lines"/>
							Jed's Resume
						</b-button>
					</b-col>
				</b-row>
			</b-col>
		</b-row>
	</b-container>
</template>

<script>
	import GrantCycle from '@/components/GrantCycle';
	import StrategicPlanning from '@/components/StrategicPlanning';

	export default {
		components: {
			GrantCycle,
			StrategicPlanning
		},
		data() {
			return {
				showStrategicPlanning: false,
				showGrantCycle: false
			};
		}
	};
</script>
