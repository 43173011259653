<template>
	<b-container>
		<b-row class="align-items-center my-5">
			<b-col md="8" class="text-center">
				<h2>Organizational Development Consulting</h2>
				<b-row cols="1" cols-sm="2" class="mb-5 mb-md-0">
					<b-col v-for="point in points" :key="point.text" class="mt-4">
						<font-awesome-icon :icon="point.icon" size="2x" class="text-primary d-block mx-auto"/>
						{{ point.text }}
					</b-col>
				</b-row>
			</b-col>
			<b-col md="4">
				<figure class="figure">
					<b-img src="/assets/images/intro.png" width="485" height="364" class="figure-img" fluid-grow rounded/>
					<figcaption class="figure-caption">Jed Blume at Sunset Ledge in the Green Mountain National Forest</figcaption>
				</figure>
			</b-col>
		</b-row>
	</b-container>
</template>

<script>
	export default {
		data() {
			return {
				points: [
					{
						icon: 'fa-solid fa-user-tie',
						text: 'Doing business as Green Mountain Grant Services, Jed Blume is a freelance development consultant.'
					},
					{
						icon: 'fa-solid fa-handshake',
						text: 'Partners primarily with organizations, academics, and artists in Vermont.'
					},
					{
						icon: 'fa-solid fa-people-roof',
						text: 'Pursues grant funding and guides strategic planning within the sectors of youth and family services, healthcare, education, the environment, and the arts.'
					},
					{
						icon: 'fa-solid fa-file-pen',
						text: 'References and writing samples available upon request.'
					}
				]
			}
		}
	}
</script>
