<template>
	<div class="bg-dark text-light">
		<b-container class="py-5">
			<b-row cols="1" cols-md="3" align-h="center" align-v="center">
				<contact/>
				<b-col class="text-center">
					<h2 class="my-4">
						Call or email to set up a free consultation
					</h2>
					<h4 class="my-4">
						For inquiries regarding Jed's work as a musician,
						please see <a href="https://jedblume.com/">jedblume.com</a>
					</h4>
					<div class="my-4">
						© Green Mountain Grant Services 2016-{{ new Date().getFullYear() }}
					</div>
				</b-col>
				<credentials/>
			</b-row>
		</b-container>
	</div>
</template>

<script>
	import Contact from '@/components/Contact';
	import Credentials from '@/components/Credentials';

	export default {
		components: {
			Contact,
			Credentials
		}
	};
</script>
