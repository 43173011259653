<template>
	<div id="partners" class="bg-primary text-light">
		<b-container class="pb-5 text-center">
			<div v-for="partnerGroup in partnerGroups" :key="partnerGroup.title">
				<h2 class="py-5 m-0">{{partnerGroup.title}}</h2>
				<b-row cols="3" cols-md="5" class="align-items-center justify-content-center g-3">
					<b-col v-for="partner in partnerGroup.partners" :key="partner.url">
						<b-link :href="partner.url" target="_blank">
							<b-img fluid-grow :src="`/assets/images/logos/${partner.src}`" style="aspect-ratio: 1; object-fit: contain;"/>
						</b-link>
					</b-col>
				</b-row>
			</div>
		</b-container>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				partnerGroups: [
					{
						title: 'Current Nonprofit Partners',
						partners: [
							{
								url: 'https://www.neyt.org',
								src: 'neyt.jpg'
							},
							{
								url: 'https://vtjazz.org/',
								src: 'vjc.jpg'
							},
							{
								url: 'https://richearthinstitute.org',
								src: 'rich-earth.gif'
							},
							{
								url: 'https://www.hatchspace.org',
								src: 'hatch-space.png'
							},
							{
								url: 'http://commonsnews.org',
								src: 'tco.png'
							},
							{
								url: 'https://windhamcounty.dental',
								src: 'wcdc.png'
							},
							{
								url: 'https://www.theatreadventure.org',
								src: 'ta.png'
							},
							{
								url: 'https://bgcbrattleboro.org',
								src: 'bgc.jpg'
							},
							{
								url: 'https://nhcrafts.org',
								src: 'lnhc.svg'
							},
							{
								url: 'https://vcphoto.org',
								src: 'vcp.png'
							}
						]
					},
					{
						title: 'Past Nonprofit Partners',
						partners: [
							{
								url: 'https://www.unitedwaywindham.org',
								src: 'united-way.jpg'
							},
							{
								url: 'https://www.insightphotography.org',
								src: 'in-sight.jpg'
							},
							{
								url: 'https://www.warriorconnection.org',
								src: 'twc.png'
							},
							{
								url: 'https://www.sandglasstheater.org',
								src: 'sandglass-theater.png'
							},
							{
								url: 'https://www.brattleboro.com/downtown-brattleboro-alliance',
								src: 'downtown-brattleboro-alliance.png'
							},
							{
								url: 'https://kidsplaycevt.org',
								src: 'kidsplayce.jpg'
							},
							{
								url: 'https://bmcvt.org',
								src: 'bmc.jpg'
							},
							{
								url: 'https://groundworksvt.org',
								src: 'gwc.png'
							},
							{
								url: 'https://www.foodconnects.org',
								src: 'food-connects.png'
							}
						]
					}
				]
			};
		}
	};
</script>
